<template>
  <div>
    <v-col cols="12" v-if="isLoading">
      <SkeletonLoaders type="card-heading, list-item-three-line" />
    </v-col>
    <div v-else class="themes-page">
      <v-row no-gutters>
        <v-col cols="12" class="page-header d-flex mt-2">
          <span class="page-title">{{ $t('Themes') }}</span>
          <span class="d-flex align-center">
            <!-- <v-btn small color="primary" class="ml-2" @click="modalChangeTheme = true"> Change theme</v-btn> -->
          </span>
          <v-spacer></v-spacer>
          <span @click="gotoStore"> <i class="show-password far fa-eye"></i> <a href="#">View your store</a> </span>
          <!-- <v-btn color="primary" @click.stop="drawer = !drawer">More filters </v-btn> -->
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4">
        <v-col cols="5" class="d-flex flex-column">
          <h4>Live theme</h4>
          <span class="live-theme-desc">
            This is the theme customers see when they visit your store.
          </span>
        </v-col>
        <v-col cols="7" class="d-flex">
          <div class="elevation-form theme-custom">
            <v-row no-gutters class="pa-3">
              <v-col cols="12" class="d-flex align-center">
                <div>
                  <div class="d-flex">
                    <h4>Themes : {{ data.name ? data.name.toUpperCase() : data.id }}</h4>
                  </div>
                  <div>Id Theme : {{ data.id }}</div>

                  <p>Last saved on {{ datetime.format(data.updated_at, 'YYYY-MM-DD HH:mm:ss') }}</p>
                </div>
                <v-spacer></v-spacer>
                <div v-if="data.id" class="d-flex">
                  <!-- <v-btn color="white" class="mr-2" disabled>Action</v-btn> -->
                  <v-btn
                    color="primary"
                    @click="
                      $router.push({
                        name: 'website.themes.editor',
                        params: {
                          themeId: data.id,
                        },
                      })
                    "
                    >Customize</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" class="d-flex align-center justify-center image-theme pa-5">
                <div>
                  <img :src="data.previewDesktopImage" alt="" srcset="" />
                </div>
                <!-- <div>
                  <img :src="`https://minio.lattehub.com/lattehub/static/20220217/12.png`" alt="" srcset="" />
                </div> -->
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" class="my-4 hidden">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4 hidden">
        <v-col cols="5" class="d-flex flex-column">
          <h4>More themes</h4>
          <span class="live-theme-desc">
            Manage your store's themes. Add and publish themes to change your online store's appearance.
          </span>
          <div class="hidden"><v-btn @click="modalCopyTheme = true" color="">Copy a theme </v-btn></div>
        </v-col>
        <v-col cols="7" class="d-flex flex-column">
          <div class="elevation-form theme-custom">
            <v-row no-gutters class="pa-3">
              <v-col cols="4" class="d-flex align-center">
                <h4>Theme name</h4>
              </v-col>
              <v-col cols="3" class="d-flex align-center">
                <!-- Last saved -->
              </v-col>
              <v-col cols="5" class="d-flex align-center">
                <h4>Actions</h4>
              </v-col>
            </v-row>
            <v-row v-for="item in listTheme" :key="item.id" no-gutters class="pa-3">
              <v-col cols="4" class="d-flex"> {{ item.name }} {{ item.id }} </v-col>
              <v-col cols="3" class="d-flex align-center">
                <!-- {{ item.updated_at.substring(0, 7) }} -->
              </v-col>
              <v-col cols="5" class="d-flex ">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="text-main">
                      Actions
                    </div>
                  </template>
                  <v-list>
                    <v-list-item class="pointer">
                      <v-list-item-title @click="actionTheme(`publish`, item)">Publish</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="pointer">
                      <v-list-item-title @click="actionTheme(`duplicate`, item)">Duplicate</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="pointer">
                      <v-list-item-title @click="actionTheme(`remove`, item)">Remove</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <span
                  color="primary"
                  class="text-main ml-2 pointer"
                  style=""
                  @click="
                    $router.push({
                      name: 'website.themes.editor',
                      params: {
                        themeId: item.id,
                      },
                    })
                  "
                  >Customize</span
                >
                <!-- <span></span> -->
              </v-col>
            </v-row>
          </div>
          <div class="elevation-form theme-custom mt-3">
            <v-row no-gutters class="pa-3">
              <v-col cols="12" class="d-flex align-center">
                <div>
                  <div class="d-flex">
                    <h4>Dcomcy templates</h4>
                  </div>

                  <p>Explore Dcomcy's templates, all designed to offer the best home page customization.</p>
                </div>
                <v-spacer></v-spacer>
                <div v-if="data.id" class="d-flex">
                  <!-- <v-btn color="white" class="mr-2" disabled>Action</v-btn> -->
                  <v-btn color="primary" @click="openTemplate">Explore more templates</v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <modal model="modalCopyTheme" width="700" title="Copy a theme" :callback="copyTheme">
      <template slot="content">
        <div>
          <!-- {{ count }} -->
          <p style="font-weight:400" class="fz-16">
            To copy a theme of a store, you need to have permission to access Themes of that store.
          </p>
          <v-text-field placeholder="" v-model="idClone"></v-text-field>
        </div>
      </template>
    </modal>
    <modal model="modalChangeTheme" title="Change theme" :callback="changeTheme">
      <template slot="content">
        <div>
          <!-- {{ count }} -->
          <p style="color:red;font-weight:400" class="fz-16">
            If change theme config will delete. This action cannot be reversed ?
          </p>
          <v-radio-group v-model="nameTheme">
            <v-radio value="hydro" label="Default"></v-radio>
            <v-radio value="newchick" label="Fashion"></v-radio>
            <!-- <v-radio value="book" label="Book"></v-radio> -->
          </v-radio-group>
        </div>
      </template>
    </modal>
    <modal model="modalChangeTemplate" width="700" title="" :callback="changeTemplate">
      <template slot="content">
        <div class="mb-3">
          <v-btn-toggle v-model="addTT" mandatory>
            <v-btn value="template">
              Template
            </v-btn>

            <!-- <v-btn value="theme">
              Theme
            </v-btn> -->
          </v-btn-toggle>
        </div>
        <div>
          <v-row v-if="addTT === 'template'">
            <v-col
              cols="4"
              class="pointer"
              :class="activeTemplate === k ? `active-template` : ``"
              v-for="(i, k) in templates"
              :key="k"
              @click="selectTemplate(k)"
            >
              <div style="width:200px; height:250px">
                <img width="100%" height="100%" style="object-fit:cover" :src="i.preview" />
              </div>
              <h4>{{ i.name }}</h4>
            </v-col>
          </v-row>
          <v-row n v-if="addTT === 'theme'">
            <v-col
              cols="4"
              class="pointer"
              :class="activeTheme === i.id ? `active-theme-c` : ``"
              v-for="(i, k) in [
                {
                  name: 'Default',
                  id: 'hydro',
                  preview: 'https://minio.lattehub.com/lattehub/static/20220228/1646043836054-123.png',
                },
                {
                  name: 'Fashion',
                  id: 'newchick',
                  preview: 'https://minio.lattehub.com/lattehub/static/20220217/12.png ',
                },
              ]"
              :key="k"
              @click="selectTheme(i)"
            >
              <div style="width:200px; height:250px">
                <img width="100%" height="100%" style="object-fit:cover" :src="i.preview" />
              </div>
              <h4>{{ i.name }}</h4>
            </v-col>
          </v-row>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import datetime from '@/helpers/datetime';
import { apiThemes } from '@/apis/theme.js';
import STORAGE_NAME from '@/const/storage';
import { domainService } from '@/apis/domain.s';
import { FETCH_PAGE_PREFERRENCE } from '../../../store/actions.type';
import settingDataDefaultNewchic from './setting-data-newchic.json';
import settingDataDefaultHydro from './settings-data.json';

export default {
  data() {
    return {
      addTT: 'template',
      modalCopyTheme: false,
      modalChangeTheme: false,
      data: {
        templateId: '',
      },
      isLoading: false,
      datetime,
      nameTheme: '',
      settingDataDefaultNewchic,
      settingDataDefaultHydro,
      settingData: null,
      modalChangeTemplate: false,
      templates: [],
      activeTemplate: null,
      activeTheme: null,
      idClone: null,
      listTheme: [],
    };
  },
  async mounted() {
    await this.getTheme();
    // console.log(this.$store.state.pagePreference.preference.storeFrontDefaultTheme);
    // this.nameTheme = this.$store.state.pagePreference.preference.storeFrontDefaultTheme || `hydro`;
    this.nameTheme = localStorage.getItem(`nameTheme`);
    this.getTemplate();
  },
  async created() {},
  computed: {
    storeFrontDefaultTheme() {
      return this.$store.state.pagePreference.preference;
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    },
  },
  watch: {
    storeFrontDefaultTheme() {
      this.nameTheme = this.$store.state.pagePreference.preference.storeFrontDefaultTheme || `hydro`;
    },
  },

  methods: {
    async actionTheme(type, item) {
      try {
        if (type === 'publish') {
          await apiThemes.activeTheme(item.id);
        }
        if (type === 'duplicate') {
          await apiThemes.cloneTheme(item.id);
        }
        if (type === 'remove') {
          await apiThemes.removeTheme(item.id);
        }
        await this.getTheme();
      } catch (error) {}
    },
    async copyTheme() {
      try {
        let res = await apiThemes.cloneTheme(this.idClone);
        await this.getTheme();
        setTimeout(() => {
          this.modalCopyTheme = false;
        }, 500);
        console.log(res, 'copy');
      } catch (error) {}
    },
    selectTemplate(k) {
      console.log(k, 'tem');
      this.activeTemplate = k;
    },
    selectTheme(i) {
      this.activeTheme = i.id;
    },
    async getTemplate() {
      try {
        let res = await apiThemes.getTemplateTheme();
        this.templates = res.data;
        console.log(`getTemplate`, res);
      } catch (error) {}
    },
    openTemplate() {
      this.modalChangeTemplate = !this.modalChangeTemplate;
    },
    async submitConfig() {
      try {
        this.isLoadingSubmit = true;
        if (this.nameTheme === 'newchick') {
          await apiThemes.updateConfig(this.data.id, this.settingData.id, { ...this.settingDataDefaultNewchic });
        }
        if (this.nameTheme === 'hydro') {
          await apiThemes.updateConfig(this.data.id, this.settingData.id, { ...this.settingDataDefaultHydro });
        }
        // await this.getConfigTheme();
        // this.changeConfig();
      } catch (error) {
        console.log('error', error);
        //empty
      }
      this.isLoadingSubmit = false;
    },
    async changeTheme() {
      try {
        let res = await apiThemes.changeTheme(this.nameTheme);
        await this.submitConfig();
        console.log(this.settingDataDefault);
        this.modalChangeTheme = false;
      } catch (error) {
        console.log('error', error);
      }
    },
    gotoStore() {
      window.open('https://' + this.$store.state.auth.accountInfo.rDomain);
    },
    async getTheme() {
      this.isLoading = true;
      try {
        let res = await apiThemes.get();
        this.data = res.data[0];
        this.listTheme = res.data.filter((item, index) => {
          return index !== 0;
        });
        console.log(this.listTheme);
        console.log(this.nameTheme);
        let getConfig = await apiThemes.getConfig(this.data.id);
        this.settingData = getConfig.data[0];
        // let domainData = await domainService.list();
        // if (domainData.data && domainData.data.length > 0) {
        //   let domainPrimary = domainData.data.find(item => item.domainType === 'Primary');
        //   if (domainPrimary) {
        //     localStorage.setItem('host', domainPrimary.host);
        //   } else {
        //     localStorage.setItem('host', localStorage.getItem('store-domain'));
        //   }
        // } else {
        //   localStorage.setItem('host', localStorage.getItem('store-domain'));
        // }
      } catch (error) {
        //empty
      }
      this.isLoading = false;
    },
    async changeTemplate() {
      try {
        if (this.addTT === 'template') {
          this.nameTheme = this.templates[this.activeTemplate].type;
          await apiThemes.addTemplate(this.templates[this.activeTemplate].id);
          // localStorage.setItem('nameTheme', this.templates[this.activeTemplate].type);
        }
        if (this.addTT === 'theme') {
          await apiThemes.addTheme({
            templateId: this.activeTheme,
          });
        }
        await this.getTheme();
        // await apiThemes.changeTheme(this.nameTheme);
        // await
        // await apiThemes.updateConfig(this.data.id, this.settingData.id, { ...this.templates[this.activeTemplate] });
        this.modalChangeTemplate = false;
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss">
.themes-page {
  .text-main {
    color: var(--v-primary-base);
    text-decoration: underline;
  }
  .theme-custom {
    width: 100%;
    // min-height: 500px;
  }
  .live-theme-desc {
    width: 70%;
    font-size: 1em;
    font-weight: 400;
  }
  .image-theme {
    img {
      max-width: 100%;
    }
  }
}
.active-template,
.active-theme-c {
  border: 1px solid var(--v-primary-base);
}
</style>
